import React, {Component} from "react";
import "../styles/bg.css";
import Helmet from "react-helmet";

export default class extends Component {
    render() {
        return (
            <div style={{
                minHeight: '100vh',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
            }}>
                <Helmet>
                    <style>{'body:before { background-image:none; background-color: #FFF; }'}</style>
                </Helmet>
                <div style={{"width":"100vw", "textAlign":"center","fontSize":"1.3rem","color":"#CCC"}}>交易完成或失敗，請返回APP</div>
            </div>
        );
    }
};